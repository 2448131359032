import React from 'react'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'

const PageHeader = () => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <h1>Seeking ambitous problem solvers</h1>
        <p>
          We're creating something special here, and we're looking for people
          who build with speed and quality.
        </p>

        <Button href="#open-positions" variant="primary">
          View open positions
        </Button>
      </div>
      <div className={styles.expandedContent}>
        <div className={styles.imageGallery}>
          <div className={styles.leftContainer}>
            <img src="/images/careers-1.jpg" alt="Careers" />
          </div>
          <div className={styles.rightContainer}>
            <img src="/images/careers-2.jpg" alt="Careers" />
          </div>
        </div>
      </div>
      <div className={styles.content} style={{ textAlign: 'left' }}>
        <p style={{ fontWeight: '500' }}>What makes a product truly great?</p>

        <p>
          At{' '}
          <span style={{ color: 'var(--primary)', fontWeight: '500' }}>
            Atlas
          </span>
          , it's all about improving how people work with location data. Whether
          it's a simple travel map or a full enterprise solution, we focus on
          every detail.
        </p>

        <p>But it all starts with our team.</p>

        <p>
          We believe that working together in-person fuels creativity and
          problem-solving. It’s one of the reasons we’re different. We push each
          other to grow, learn, and do our best work.
        </p>

        <p>
          We're passionate about diversity and inclusion. Everyone gets a chance
          to learn, grow, and succeed.
        </p>

        <p>
          If you're ready to take on challenges, solve tough problems, and be
          part of something exciting, we’d love to talk.
        </p>
      </div>
    </div>
  )
}

export default PageHeader
