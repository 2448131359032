import React from 'react'
import styles from './Mission.module.scss'
import Button from '../common/Button'

const Mission = () => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <h2>Atlas exists to improve how we understand the world around us</h2>
        <div>
          <p>
            Through the power of GIS, Atlas helps teams worldwide make smarter
            decisions. From mapping natural resources to planning greener
            cities, we enable better data, better experiences, and better
            services that people can rely on.
          </p>

          <p>
            We know our mission is ambitious—and that’s the point. Tackling
            complex sustainability challenges is hard work, but it's worth it 🌍
          </p>
        </div>
      </div>
    </div>
  )
}

export default Mission
