import React, { useState } from 'react'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import PageHeader from '../components/careers/PageHeader'
import Mission from '../components/careers/Mission'
import OpenPositions from '../components/careers/OpenPositions'

const CareersPage = ({ data }) => {
  const positions = data.positions.nodes

  return (
    <Layout
      meta={{
        description:
          'We are looking for passionate developers and designers to join our team. Apply now!',
        title: 'Careers at Atlas | Atlas.co',
        type: 'website',
        absoluteImageUrl: 'https://atlas.co/images/careers-og.png'
      }}
      title={'Join the Team'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <Mission />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <OpenPositions positions={positions} />
        </Fade>
      </main>
    </Layout>
  )
}

export default CareersPage

export const pageQuery = graphql`
  query {
    positions: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { fileAbsolutePath: { regex: "/open-positions/" } }
      limit: 100
    ) {
      nodes {
        id
        frontmatter {
          title
          location
          partTime
        }
      }
    }
  }
`
