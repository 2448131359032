import React from 'react'
import styles from './OpenPositions.module.scss'
import Button from '../common/Button'
import { Link } from 'gatsby'
import _ from 'lodash'
import { ArrowRightIcon } from '@atlas-co/icons'

const OpenPositions = ({ positions }) => {
  return (
    <div className={styles.section} id="open-positions">
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>Join the team</h2>
          <p>
            You arrived on this page with a purpose. Take the next step and
            engage in a conversation with us to explore if our open roles align
            with your aspirations.
          </p>
        </div>
        <div className={styles.positions}>
          <div className={styles.positionsHeader}>
            <h3>Open positions</h3>
          </div>
          <div className={styles.list}>
            {positions.map(position => (
              <Link
                to={`/open-positions/${_.kebabCase(
                  position.frontmatter.title
                )}/`}
              >
                <div className={styles.position}>
                  <div className={styles.positionDetails}>
                    <h4>
                      {position.frontmatter.title}
                      <ArrowRightIcon />{' '}
                    </h4>
                    <span>{position.frontmatter.location}</span>
                    <span>
                      {position.frontmatter.partTime
                        ? 'Part-time'
                        : 'Full time'}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpenPositions
